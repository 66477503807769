<template>
  <div class="stock-manage">
    <div class="stock-manage-wrapper">
        <div class="stock-manage-wrapper-header">
            <el-button @click="state='Catalog'" type="primary">קטלוג</el-button>
            <el-button @click="state='AddItem'" style="margin-right:5px;" type="danger">הוסף פריט</el-button>
            <el-button @click="state='ExcelReload'" style="margin-right:5px;" type="success">טעינה מאקסל</el-button>
            <el-button @click="state='CounterSummary'" style="margin-right:5px;" type="primary">סיכום ספירות</el-button>
        </div>
        <div class="stock-manage-wrapper-content">
            <keep-alive>
                <component 
                    @delete_item="handle_delete_item" 
                    @reload_data="init" 
                    @after_edit="handle_after_edit"
                    @edit_item="handle_edit_item"
                    @reset_edit="selected_item=null"
                    :data="data" :is="state"
                    :edit="selected_item"
                />
            </keep-alive>
        </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from '@vue/runtime-core'
import {get_data_from_db} from './scripts/scripts'
export default {
    components:{
        ExcelReload:defineAsyncComponent(()=>import('./components/ExcelReload.vue')),
        AddItem:defineAsyncComponent(()=>import('./components/AddItem.vue')),
        Catalog:defineAsyncComponent(()=>import('./components/Catalog.vue')),
        CounterSummary:defineAsyncComponent(()=>import('./components/CounterSummary.vue')),
    },
    setup(){
        const state = ref('Catalog')
        const data = ref([])
        const selected_item = ref(null)

        const handle_delete_item = (item) => {
            data.value = data.value.filter(({uid})=>uid != item.uid)
        }

        const handle_after_edit = async() => {
            await init()
            state.value = 'Catalog'
        }

        const init = async() => {
            data.value = await get_data_from_db()
        }

        const handle_edit_item = (item) => {
            selected_item.value = item
            state.value = 'AddItem'
        }

        init()
        return{
            handle_delete_item,
            handle_edit_item,
            handle_after_edit,
            init,
            state,
            data,
            selected_item,
        }
    }
}
</script>

<style scoped>
    .stock-manage{
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .stock-manage-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        padding: 5px;
    }
    .stock-manage-wrapper-header{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        border-radius: 10px;
        display: flex;
        align-items: center;
    }
    .stock-manage-wrapper-content{
        width: 100%;
        height: calc(100% - 50px);
    }

    :global(.el-button+.el-button){
        margin: 0;
    }

</style>